<template>
  <div class="blocked" @click="back()">
    <div>你的账户已被冻结，请联系管理员解冻</div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.blocked{
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

